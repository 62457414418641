<template>
  <TableRender
    show-filter-text
    :config="config"
    :show-export="false"
    endpoint="/admin/documents"
    table-title="Documentos"
    @on-click-add="onClickAdd"
  >
    <template #bread-crumb-buttons>
      <q-btn
        flat
        dense
        color="primary"
        class="col-auto btn-view"
        :to="addLink"
      >
        <FileAddIcon />
        <q-tooltip
          content-class="bg-primary text-bold"
          content-style="font-size: 12px"
        >
          Adicionar Documento
        </q-tooltip>
      </q-btn>
    </template>
  </TableRender>
</template>

<script>
import listConfig from '../config/list'
import TableRender from '@/components/table/TableRender'
import { ROUTE_DOCUMENT_CREATE } from '../constants'
import FileAddIcon from '@/assets/icons/FileAdd.svg'

export default {
  components: {
    FileAddIcon,
    TableRender
  },

  data () {
    const vm = this
    const getters = vm.$store.getters
    const config = listConfig()
    if (getters.isGeneralRole) config.pagination.query.scope = 'G'
    if (getters.isRegionRole) config.pagination.query.scope = 'R'
    if (getters.isLocalRole) config.pagination.query.scope = 'L'

    return {
      config,
      addLink: { name: ROUTE_DOCUMENT_CREATE }
    }
  },

  mounted () {
    const vm = this
    const isAdmin = vm.$store.getters.isAdmin
    if (!isAdmin) return vm.$router.go(-1)
  },

  methods: {
    onClickAdd () {
      this.$router.push({ name: ROUTE_DOCUMENT_CREATE })
    }
  }
}

</script>
